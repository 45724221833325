exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/ContactPage.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-default-page-tsx": () => import("./../../../src/templates/DefaultPage.tsx" /* webpackChunkName: "component---src-templates-default-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/HomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-info-page-tsx": () => import("./../../../src/templates/InfoPage.tsx" /* webpackChunkName: "component---src-templates-info-page-tsx" */),
  "component---src-templates-products-page-tsx": () => import("./../../../src/templates/ProductsPage.tsx" /* webpackChunkName: "component---src-templates-products-page-tsx" */)
}

